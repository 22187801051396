import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { navigate } from "gatsby-link";
import { theme, BREAKPOINTS } from "../utils/styled_config";

import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Col from "../components/Col";
import styled, { css } from "styled-components";
import Title from "../components/Title";
import Layout from "../components/Layout";
import Recaptcha from "react-recaptcha";
import Seo from "../components/Seo";

const schema = yup.object().shape({
  name: yup.string().required("This is required."),
  email: yup
    .string()
    .required("This is required.")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,13}$/i,
      "Please enter a valid email."
    ),
  mobile: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Please enter a valid phone number."
    ),
  company: yup.string().required("This is required."),
  message: yup.string().required("This is required."),
});

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch(`${process.env.GATSBY_CONTACT_US_API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.GATSBY_CONTACT_US_API_KEY,
        },
        body: JSON.stringify(data),
      });
      const json = await response.json();
      if (response.ok) {
        navigate("/thank-you");
      } else {
        // show error message
        setError(json.message);
      }
    } catch (err) {
      // show error
      setError(err.message);
    }
    setIsSubmitting(false);
  };

  return (
    <Layout>
      <Seo title="Contact Us" />
      <Section>
        <Container>
          <Row justify="center">
            <Col lg={8}>
              <Center>
                <Title size={2} mb={10}>
                  Contact Us
                </Title>
              </Center>
              <form onSubmit={handleSubmit(onSubmit)}>
                {error && <p className="text-danger">{error}</p>}

                <FieldGroup errors={errors.company}>
                  <Label htmlFor="company">
                    Company Name
                    <sup>*</sup>
                  </Label>
                  <FieldContainer>
                    <Field
                      {...register("company")}
                      placeholder="John Doe Inc."
                    />
                  </FieldContainer>
                  {errors.company && (
                    <FieldError>{errors.company.message}</FieldError>
                  )}
                </FieldGroup>
                <FieldGroup errors={errors.name}>
                  <Label htmlFor="name">
                    Contact Person
                    <sup>*</sup>
                  </Label>
                  <FieldContainer>
                    <Field {...register("name")} placeholder="John Doe" />
                  </FieldContainer>
                  {errors.name && (
                    <FieldError>{errors.name.message}</FieldError>
                  )}
                </FieldGroup>
                <FieldGroup errors={errors.email}>
                  <Label htmlFor="email">
                    Email Address
                    <sup>*</sup>
                  </Label>
                  <FieldContainer>
                    <Field
                      {...register("email", {
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      })}
                      placeholder="example@email.com"
                    />
                  </FieldContainer>
                  {errors.email && (
                    <FieldError>{errors.email.message}</FieldError>
                  )}
                </FieldGroup>
                <FieldGroup>
                  <Label htmlFor="mobile">
                    Contact Number
                    <sup>*</sup>
                  </Label>
                  <FieldContainer>
                    <Field
                      {...register("mobile")}
                      placeholder="+63 9638-3422"
                    />
                  </FieldContainer>
                  {errors.mobile && (
                    <FieldError>{errors.mobile.message}</FieldError>
                  )}
                </FieldGroup>
                <FieldGroup errors={errors.message}>
                  <Label htmlFor="">
                    Message
                    <sup>*</sup>
                  </Label>
                  <FieldContainer>
                    <Textarea
                      as="textarea"
                      {...register("message", {
                        required: true,
                      })}
                      placeholder="Tell us about your project"
                    />
                  </FieldContainer>
                  {errors.message && (
                    <FieldError>{errors.message.message}</FieldError>
                  )}
                </FieldGroup>
                <Center>
                  <Recaptcha
                    render="explicit"
                    sitekey={`${process.env.GATSBY_RECAPTCHA}`}
                    theme="dark"
                    verifyCallback={() => setValidated(true)}
                    onloadCallback={console.log.bind(this, "recaptcha loaded")}
                  />
                  <Button type="submit" disabled={isSubmitting || !validated}>
                    {isSubmitting ? "Sending..." : "Submit Inquiry"}
                  </Button>
                </Center>
              </form>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

const Center = styled.div`
  text-align: center;

  #g-recaptcha > div:first-child {
    margin: 0 auto 20px;
  }
`;

const Field = styled.input`
  width: 100%;
  height: 44px;
  background: none;
  border: 0;
  border-bottom: 1px solid #757575;
  color: #fff;
  transition: ${theme.transition};

  &:focus {
    border-color: white;
    outline: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    height: 48px;
  }
`;

const FieldGroup = styled.div`
  margin-bottom: 1.5rem;

  input,
  ${Field} {
    ${(p) =>
      p.errors &&
      css`
        border-color: ${theme.color.danger};
      `}
  }
`;

const Label = styled.label`
  font-size: 14px;

  sup {
    color: ${theme.color.danger};
  }
`;

const FieldContainer = styled.div`
  position: relative;
`;

const FieldError = styled.div`
  color: ${theme.color.danger};
  font-size: 12px;
`;

const Textarea = styled(Field)`
  height: 100px;
  resize: none;
`;

const Button = styled.button`
  font-family: "Prompt", sans-serif;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border: 0;
  background: #00b8d4;
  padding: 8px 16px;
  min-width: 150px;
  border-radius: 4px;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

export default ContactUs;
